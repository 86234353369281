<template>
  <!--begin::Layout-->
  <div class="d-flex flex-column flex-xl-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid">
      <!--begin:::Tabs-->
      <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8">
        <!--begin:::Tab item-->
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/stillboard/overview"
            active-class="active"
          >
            {{$t("Vue d'ensemble")}}
          </router-link>
        </li>
        <!--end:::Tab item-->

        <!--begin:::Tab item-->
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/stillboard/offres"
            active-class="active"
          >
            {{$t("Offres commerciales")}}
          </router-link>
        </li>
        <!--end:::Tab item-->

        <!--begin:::Tab item-->
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/stillboard/commandes"
            active-class="active"
          >
            {{$t("Commandes")}}
          </router-link>
        </li>
        <!--end:::Tab item-->

        <!--begin:::Tab item-->
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/stillboard/factures"
            active-class="active"
          >
            {{$t("Factures")}}
          </router-link>
        </li>
        <!--end:::Tab item-->
      </ul>
      <!--end:::Tabs-->
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "customer-details",
  setup() {

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Board StillOnline", [
        "board",
        "stillOverview",
      ]);
    });
  },
});
</script>
